// 3rd party
import { useEffect, useState, useRef } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'

// Contentful
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { enableContent2BlocksPreview } from '@/lib/contentful/module'
import { NextImage } from '../Image/NextImage'
import Image from 'next/image'

// Components
import CTA from '@/components/CTA'
import Markdown from '@/components/atoms/Markdown'
import { default as pagePaths } from '../../public/promotion-urls.json'
import useIsInViewport from '@/hooks/useIntersection'
import {
  contentClick,
  selectContentPromotionEvent,
  viewContentPromotionEvent,
} from '@/lib/ga-events'
import { getContentModel } from '@/lib/helper'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import styleConfig from '@/styles/style-config'
import ReactMarkdown from 'react-markdown'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Scrollbar, EffectFade, Autoplay } from 'swiper'


import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'
import 'swiper/components/scrollbar/scrollbar.min.css'

SwiperCore.use([Navigation, Pagination, Scrollbar, EffectFade, Autoplay])

const DynamicVideoPlayer = dynamic(() => import('@/components/VideoPlayer/SimpleVideoPlayer'), {
  loading: () => null,
  ssr: false,
})

export default function ModuleContent2Blocks(props) {
  const router = useRouter()
  const { preview } = props
  const [item, setItem] = useState(props)
  const contentRef = useRef(null)
  const textColor = item?.textColor
  const bgColor = item?.backgroundColor
  const [success, setSuccess] = useState(false)
  const [videoError, setVideoError] = useState(false)
  const inViewport = useIsInViewport(contentRef)
  let pageModel = getContentModel('page')
  let contentModel = getContentModel('moduleContent2Blocks')
  const tabletScreen = Number(styleConfig.theme.screens.md.replace('px', ''))
  const mobileScreen = Number(styleConfig.theme.screens.sm.replace('px', ''))
  const textClass = textColor ? `text-color-${textColor.replace('#', '')}` : '';
  const windowDimensions = useWindowDimensions()

  let videoUrl = item?.videos?.desktop;

  if (windowDimensions.width <= tabletScreen) {
    if (windowDimensions.width <= mobileScreen) {
      videoUrl = item?.videos?.mobile
    }else{
      videoUrl = item?.videos?.tablet
    }
  }

  useEffect(()=>{
    setVideoError(false);
  }, [videoUrl])

  useEffect(async () => {
    if (preview) {
      const res = await enableContent2BlocksPreview(props, preview)
      setItem(res)
    }
  }, []);

  useEffect(() => {
    if (inViewport && props?.textAndImage && router.asPath.startsWith('/products')) {
      if (success) return
      const sucessFunction = async () => {
        try {
          let currentPath = router.asPath
          let promotionId =
            (process.env.NEXT_PUBLIC_VERCEL_URL || process.env.NEXT_PUBLIC_BASE_URL) + currentPath
          promotionId = promotionId.startsWith('http') ? promotionId : 'https://' + promotionId
          //PROMOTION NAME & CREATIVE NAME & CREATIVE SLOT & locationId
          let promotionName
          let creativeName = props?.textAndImage || ''
          let creativeSlot = props?.textAndImage || ''
          let locationId = props.index + 1
          if (props.product && props.product.indexValue) {
            locationId += props.product.indexValue
          }
          if (currentPath == '/') {
            promotionName = `${pageModel?.name}::${contentModel?.name}`
          } else {
            let pageType = pagePaths.find((page) => page.path == currentPath)
            promotionName = pageType?.type
              ? getContentModel(pageType?.type)?.name + `::${contentModel?.name}`
              : `${pageModel?.name}::${contentModel?.name}`
          }
          let eventData = [
            {
              promotion_id: promotionId,
              promotion_name: promotionName,
              creative_name: creativeName,
              creative_slot: creativeSlot,
              location_id: locationId,
            },
          ]

          let executed = viewContentPromotionEvent(eventData)
          if (executed) {
            setSuccess(true)
          }
        } catch (error) {
          console.error('View Promotion execution failed:', error)
        }
      }

      const intervalId = setInterval(async () => {
        if (!success) {
          await sucessFunction()
        } else {
          clearInterval(intervalId)
        }
      }, 1000)

      return () => clearInterval(intervalId)
    }
  }, [inViewport, success, router.asPath])

  const renderOptions = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: (node, children) => {
        if (node.data.target.sys.contentType.sys.id === 'blogPost') {
          return (
            <a
              href={`/blog/${node.data.target.fields.slug}`}
              onClick={() => {
                contentClick(`/blog/${node.data.target.fields.slug}`, node.data.target.fields.title)
              }}
            >
              {node.data.target.fields.title}
            </a>
          )
        } else if (node.data.target.sys.contentType.sys.id === 'modulePageDescription') {
          return <>{documentToReactComponents(node?.data?.target?.fields?.content, renderImages)}</>
        }
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        const contentType = node?.data?.target?.sys?.contentType?.sys?.id
        const content = node?.data?.target?.fields
 
        if (!content) {
          return null
        }
        if (contentType === 'moduleMarkdownText') {
          return <Markdown color={textColor}>{content.markdownText}</Markdown>
        }
      },
      [BLOCKS.PARAGRAPH]: (_, children) => {
        return (
          <div className="prose-details-card break-words hyphens-auto">
            <p>{children}</p>
          </div>
        )
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        return (
          <NextImage
            src={`https://${node.data.target.fields.file.url}`}
            height={node.data.target.fields.file.details.image.height}
            width={node.data.target.fields.file.details.image.width}
            alt={node.data.target.fields.description}
            objectFit="cover"
            objectPosition="center"
          />
        )
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a
            href={node.data.uri}
            onClick={(event) => {
              event.preventDefault()
              let currentPath = router.asPath
              let promotionId =
                (process.env.NEXT_PUBLIC_VERCEL_URL || process.env.NEXT_PUBLIC_BASE_URL) +
                currentPath
              promotionId = promotionId.startsWith('http') ? promotionId : 'https://' + promotionId
              let linkText = node?.content[0]?.value
              //PROMOTION NAME & CREATIVE NAME & CREATIVE SLOT & locationId
              let promotionName
              let creativeName = props?.textAndImage || ''
              let creativeSlot = 'Body Text::' + linkText
              let locationId = props.index + 1
              if (props.product && props.product.indexValue) {
                locationId += props.product.indexValue
              }
              if (currentPath == '/') {
                promotionName = `${pageModel?.name}::${contentModel?.name}`
              } else {
                let pageType = pagePaths.find((page) => page.path == currentPath)
                promotionName = pageType?.type
                  ? getContentModel(pageType?.type)?.name + `::${contentModel?.name}`
                  : `${pageModel?.name}::${contentModel?.name}`
              }
              let eventData = [
                {
                  promotion_id: promotionId,
                  promotion_name: promotionName,
                  creative_name: creativeName,
                  creative_slot: creativeSlot,
                  location_id: locationId,
                },
              ]
              selectContentPromotionEvent(eventData)
              router.push(node.data.uri)
            }}
          >
            {children}
          </a>
        )
      },
    },
  }

  const imageUrl = item.backgroundImage || item.backgroundImage?.url
  const altTag = item.altTag || ''

  //please don't remove commented code
  // const overlayColor =
  //   item.overlayType && item.overlayType === 'Dark'
  //     ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))'
  //     : 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))'

  const headlineStyle =
    item.imageSize == '1/5'
      ? `content-custom-header font-bold md:text-2xl text-xl font-bold leading-none tracking-widest ${
          textColor ? 'text-color' : ''
        } mb-[12px]`
      : `font-bold text-[24px] md:text-[28px] text-xl font-bold leading-[32px] md:leading-[36px] tracking-[0.08em] ${
          textColor ? 'text-color' : ''
        } my-3 md:my-4 max-w-[864px]`
  const bodyTextStyle =
    item.imageSize == '2/3'
      ? `module-content-2blocks-body-text text-[#4C4444] text-[14px] md:text-[16px] leading-[22px] md:leading-[26px] first-line:font-[600] tracking-[letter-spacing: 0.02em] content2Block_content ${textClass}`
      : `module-content-2blocks-body-text prose prose-details-card content2Block_content ${textClass} ${
          router.pathname == '/affiliate' || router.pathname == '/grant-program'
            ? 'prose_affiliate'
            : router.pathname == '/ecosystems-sec'
            ? 'eco-system'
            : router.pathname === '/depot-events'
            ? 'prose_depot_event'
            : ''
        }`

  const primaryCta = {
    id: item?.primaryCta?.id || null,
    label: item?.primaryCta?.label || item?.ctaLeftText,
    openInNewTab: item?.primaryCta?.openInNewTab || false,
    openVideoPlayer: item?.primaryCta?.openVideoPlayer || false,
    url: item?.primaryCta?.url || item?.ctaLeftUrl,
    bgColor: item?.primaryCta?.backgroundColor || item?.ctaLeftBgColor,
  }

  const secondaryCta = {
    id: item?.secondaryCta?.id || null,
    label: item?.secondaryCta?.label || item?.ctaRightText,
    openInNewTab: item?.secondaryCta?.openInNewTab || false,
    openVideoPlayer: item?.secondaryCta?.openVideoPlayer || false,
    url: item?.secondaryCta?.url || item?.ctaRightUrl,
    bgColor: item?.secondaryCta?.backgroundColor,
  }

  const loadImage = () => {
    return (
      <NextImage
        src={`${imageUrl}`}
        layout="fill"
        objectFit="cover"
        objectPosition="center"
        alt={`${altTag}`}
      />
    )
  }

  if (item?.slides?.length) {
    const contentSliderRef = useRef(null);
    const swiperRef = useRef(null);
    const nextButtonRef = useRef(null);
    const prevButtonRef = useRef(null);
    const [progress, setProgress] = useState(1);
    const [isMobile, setIsMobile] = useState(windowDimensions.width < 1024);
    const [nextButtonState, setNextButtonState] = useState(false);
    const [prevButtonState, setPrevButtonState] = useState(true);
    const [contentSlider, setContentSlider] = useState(null);
    const [maxHeight, setMaxHeight] = useState(540);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 1024);
      };
    
      handleResize();
    
      window.addEventListener('resize', handleResize);
    
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    useEffect(() => {
      if (contentSlider) {
        contentSlider.changeDirection(isMobile ? 'horizontal' : 'vertical');
        contentSlider.update();
      }
    }, [isMobile]);
  
    useEffect(() => {
      if (contentSliderRef.current) {
        const slides = contentSliderRef.current.querySelectorAll('.module-content-2-block__content');
        let maxSlideHeight = 0;
  
        slides.forEach((slide) => {
          const slideHeight = slide.scrollHeight;
          if (slideHeight > maxSlideHeight) {
            maxSlideHeight = slideHeight + 20;
          }
        });
  
        if (maxSlideHeight > 540) {
          setMaxHeight(maxSlideHeight);
        }
      }
    }, [item.slides]);

    const goToSlide = (index) => {
      if (swiperRef.current && swiperRef.current.swiper) {
        swiperRef.current.swiper.slideTo(index);
      }
    };
    
    const handleSlideChange = (swiper) => {
      const isLastSlide = swiper.activeIndex + 1 === swiper.slides.length;
      const isFirstSlide = swiper.activeIndex === 0;
    
      setNextButtonState(isLastSlide);
      setPrevButtonState(isFirstSlide);
    
      goToSlide(swiper.activeIndex);
    
      const currentProgress = (swiper.activeIndex + 1) / swiper.slides.length;
      setProgress(currentProgress * 100);
    };
    
    const initializeProgress = (swiper) => {
      const initialProgress = 1 / swiper.slides.length;
      setProgress(initialProgress * 100);
    };

    const getMediaUrl = (videos, type) => {
      const url = videos?.[type]?.url || '';
      const isVideo = videos?.[type]?.type?.includes('video');
      return { url, isVideo };
    };

    const mediaType =
      windowDimensions.width <= mobileScreen
        ? 'mobile'
        : windowDimensions.width <= tabletScreen
        ? 'tablet'
        : 'desktop';

    const SlideMedia = ({ slide, mediaUrl, isVideo }) => (
      <>
        <div className={`transition-opacity duration-500 relative h-full w-full aspect-square bg-center bg-cover`}>
          <div className="imageBackground h-full content2_block_video bg-center bg-cover">
            {isVideo && mediaUrl ? (
              <DynamicVideoPlayer
                url={mediaUrl}
                playing
                playsinline
                loop
                volume={0}
                muted
                width="100%"
                height="100%"
              />
            ) : mediaUrl ? (
              <NextImage
                src={mediaUrl}
                layout="fill"
                objectFit="cover"
                objectPosition="center"
                alt={slide?.imageAlt || 'Slide image'}
              />
            ) : slide?.image ? (
              <NextImage
                src={slide?.image}
                layout="fill"
                objectFit="cover"
                objectPosition="center"
                alt={slide?.imageAlt || 'Slide image'}
              />
            ) : (
              null
            )}
          </div>
        </div>
      </>
    )
    const SlideContent = ({ slide, index }) => (
      <div className='module-content-2-block__content flex flex-col justify-center p-0 lg:p-[20px] w-full lg:max-w-[80%]'
        style={{ maxHeight: 'inherit' }}
      >
        {slide?.contentIcon && (
          <div>
            <div
              className="hidden relative lg:block mb-[20px]"
              style={{
                width: slide?.iconWidth,
                height: slide?.iconWidth,
              }}
            >
              <Image
                src={slide?.contentIcon}
                alt={slide?.contentAlt}
                quality={100}
                layout="fill"
                loading="eager"
                height={slide?.iconWidth}
                width={slide?.iconWidth}
              />
            </div>
            <div
              className="block lg:hidden mb-[20px]"
              style={{
                width: slide?.iconWidthMobile,
                height: slide?.iconWidthMobile,
              }}
            >
              <Image
                src={slide?.contentIcon}
                alt={slide?.contentAlt}
                quality={100}
                loading="eager"
                height={slide?.iconWidthMobile}
                width={slide?.iconWidthMobile}
              />
            </div>
          </div>
        )}
        {slide?.eyebrow && (
          <span
            className={`title text-xs md:text-xs uppercase font-semibold md:font-bold leading-[16px] md:leading-[15px] tracking-[0.1em] md:tracking-widest content2Block_content ${textClass} ${
              textColor ? 'text-color' : ''
            }`}
          >
            {slide.eyebrow}
          </span>
        )}
        {slide?.headline && (
          <h3 className={`${headlineStyle} font-bold tracking-[0.08em] uppercase content2Block_content ${textClass}`}>
            {slide.headline}
          </h3>
        )}
        {slide?.bodyText && <ReactMarkdown className='default-list'>{slide.bodyText}</ReactMarkdown>}
        {(slide.primaryCta?.url && slide.primaryCta?.label) ||
        (slide.secondaryCta?.url && slide.secondaryCta?.label) ? (
          <div className={`${primaryCta.url ? 'flex flex-wrap gap-2' : ''} mt-[15px] md:mt-[32px]`}>
            {slide.primaryCta?.url && slide.primaryCta?.label && (
              
              <div
                className={`${
                  !slide.secondaryCta?.url && !slide.secondaryCta?.label
                    ? 'md:w-[180px]'
                    : 'button-group-wrap flex'
                } md:h-[56px] mr-2`}
              >
                <CTA
                  className="btn btn-primary flex items-center justify-center tracking-widest py-[21px] px-[24px]"
                  label={slide.primaryCta.label}
                  openInNewTab={slide.primaryCta.openInNewTab}
                  openVideoPlayer={slide.primaryCta.openVideoPlayer}
                  url={slide.primaryCta.url}
                  background={slide.primaryCta.backgroundColor}
                  moduleProps={{
                    ...props,
                    entryTitle: `${props?.entryTitle || props?.fields?.entryTitle || props?.textAndImage || props?.sectionName} | ${index}`,
                    primaryCta: slide.primaryCta,
                  }}
                />
              </div>
            )}
            {slide.secondaryCta?.url && slide.secondaryCta?.label && (
              <div className="button-group-wrap flex">
                <CTA
                  className={`btn btn-primary flex items-center tracking-widest py-[21px] px-[24px] ${
                    item.imageSize === '2/3' && 'px-4'
                  }`}
                  label={slide.secondaryCta.label}
                  openInNewTab={slide.secondaryCta.openInNewTab}
                  openVideoPlayer={slide.secondaryCta.openVideoPlayer}
                  url={slide.secondaryCta.url}
                  background={slide.secondaryCta.backgroundColor}
                  moduleProps={{
                    ...props,
                    entryTitle: `${props?.entryTitle || props?.fields?.entryTitle || props?.textAndImage || props?.sectionName} | ${index}`,
                    secondaryCta: slide.secondaryCta,
                  }}
                />
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
 
    return (
      <div ref={contentRef} 
        className={`relative ${!item.generalPaddingDisable ? 'my-[20px] md:my-[50px]' : null}`} 
        style={{backgroundColor: bgColor, color: textColor}}
      >
        <div className={'relative flex flex-col lg:flex-row gap-1 w-full lg:items-center 2xl:max-h-[calc(95vh-200px)] overflow-hidden'}>
          {item.slides.length > 1 ? (
            <Swiper
              modules={[EffectFade, Navigation]}
              effect="fade"
              fadeEffect={{ crossFade: true }}
              ref={swiperRef}
              loop={false}
              slidesPerView={1}
              allowTouchMove={false}
              className={`w-full lg:self-stretch ${item?.imageSize === '1/5' ? 'lg:w-1/5' : item?.imageSize === '2/3' ? 'lg:w-2/3' : 'lg:w-1/2'}  ${item.imagePosition == 'left' ? 'lg:order-1' : 'lg:order-2'}`}
            >
              {item.slides.map((slide, index) => {
                const { url: mediaUrl, isVideo } = getMediaUrl(slide?.videos, mediaType);

                return (
                  <SwiperSlide key={index}>
                    <SlideMedia slide={slide} mediaUrl={mediaUrl} isVideo={isVideo} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ) : (
            <div className={`w-full ${item?.imageSize === '1/5' ? 'lg:w-1/5' : item?.imageSize === '2/3' ? 'lg:w-2/3' : 'lg:w-1/2'} 2xl:max-h-[calc(95vh-200px)] ${item.imagePosition == 'left' ? 'lg:order-1' : 'lg:order-2'}`}>
              {item.slides.map((slide, index) => {
                const { url: mediaUrl, isVideo } = getMediaUrl(slide?.videos, mediaType);

                return (
                  <SlideMedia slide={slide} mediaUrl={mediaUrl} isVideo={isVideo} />
                );
              })}
            </div>
          ) }

          <div
            className={`relative flex flex-col justify-between gap-4 pt-[20px] px-[20px] h-full min-h-[380px] lg:max-h-full 2xl:max-h-[calc(95vh-200px)] w-full overflow-hidden
              ${item.slides.length > 1 ? 'self-end' : 'self-center'}
              ${item?.imageSize === '1/5' ? 'lg:w-4/5' : item?.imageSize === '2/3' ? 'lg:w-1/3' : 'lg:w-1/2'}
              ${item.imagePosition === 'left' ? 'lg:order-2' : 'lg:order-1'} moduleContent2Blocks__content-wrapper`}
          >
            {item.slides.length > 1 ? (
              <>
                <Swiper
                  loop={false}
                  slidesPerView={1}
                  direction={isMobile ? 'horizontal' : 'vertical'}
                  allowTouchMove={isMobile}
                  autoplay={
                    item?.autoplayTime
                      ? {
                          delay: item.autoplayTime,
                          disableOnInteraction: true,
                        }
                      : false
                  }
                  modules={[Navigation, Pagination, Scrollbar, Autoplay]}
                  autoHeight={false}
                  navigation={{
                    nextEl: nextButtonRef.current,
                    prevEl: prevButtonRef.current,
                  }}
                  onSwiper={(swiperInstance) => {
                    setContentSlider(swiperInstance);
                    initializeProgress(swiperInstance);
                  }}
                  onSlideChange={handleSlideChange}
                  style={{
                    '--container-max-height': maxHeight + 'px'
                  }}
                  className={`moduleContent2Blocks__slider flex justify-center w-full overflow-hidden`}
                  ref={contentSliderRef}
                >
                  {item.slides.map((slide, index) => (
                    <SwiperSlide key={index}  tabIndex="-1">
                      <SlideContent slide={slide} index={index + 1} />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="relative w-full mt-1 mb-5">
                  <div className="flex gap-[10px] lg:gap-[15px] justify-end lg:justify-start w-full mb-[15px]">
                    <button
                      type='button'
                      ref={prevButtonRef}
                      className={`relative flex items-center justify-center w-[48px] h-[48px] bg-white rounded-full ${prevButtonState ? 'opacity-60' : null}`}
                      aria-label="Previous Slide"
                      disabled={prevButtonState}
                    >
                      <svg 
                        className='w-[18px]'
                        xmlns="http://www.w3.org/2000/svg" 
                        viewBox="0 0 40 40" 
                        width="48" 
                        height="48" 
                        focusable="false"
                        aria-hidden="true"
                      >
                        <g transform="scale(-1, 1) translate(-40, 0)">
                          <path d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z"></path>
                        </g>
                      </svg>
                    </button>

                    <button
                      type='button'
                      ref={nextButtonRef}
                      className={`relative flex items-center justify-center w-[48px] h-[48px] bg-white rounded-full ${nextButtonState ? 'opacity-60' : null }`}
                      aria-label="Next Slide"
                      disabled={nextButtonState}
                    >
                      <svg
                        className='w-[18px]'
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 40 40"
                        width="48"
                        height="48"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="M15.5 0.932L11.2 5.312 25.7 19.912 11.2 34.412 15.5 38.812 30.1 24.212 34.5 19.912 30.1 15.512 15.5 0.932Z" />
                      </svg>
                    </button>
                  </div>

                  <div className="my-slider-progress bg-gray-200 w-full max-w-[100%] overflow-hidden">
                    <div
                      className="my-slider-progress-bar bg-black"
                      style={{ width: `${progress}%` }}
                    ></div>
                  </div>
                </div>
              </>
            ) : (
              <div
                ref={contentSliderRef}
                className="w-full"
              >
                {item.slides.map((slide, index) => (
                  <SlideContent slide={slide} index={index + 1}/>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div
        ref={contentRef}
        className={`
          module-content-2blocks
          ${!item.inset ? 'w-full' : 'container'}
          ${item.imageSize == '1/5' ? 'flex flex-col gap-[50px]' : 'grid'}
          ${router.pathname == '/wader-warranty' && 'lg:mx-[80px] xl:mx-auto'}
          ${
            item?.backgroundColor === 'white'
              ? 'bg-white mb-[30px] lg:mb-[120px]'
              : item.imageSize != '1/2'
              ? bgColor
                ? 'bg-color'
                : 'bg-gray-100'
              : bgColor
              ? 'bg-color'
              : 'bg-gray-50'
          }
          ${!item.generalPaddingDisable ? 'my-[20px] md:my-[50px]' : null}
        `}
      >
        <div
          className={`
            ${
              item.inset &&
              'sm:px-0 w-full sm:w-[640px] md:w-[700px] lg:w-[970px] xl:w-[1280px] mx-auto'
            }
            content-horizontal flex flex-col lg:flex-row overflow-hidden
            ${
              item.imageSize == '1/5' &&
              `lg:mb-[${router.pathname == '/wader-warranty' ? '0' : '80px'}]`
            }
          `}
        >
          <div
            className={`
              w-full ${item.imageSize == '2/3' ? 'lg:h-[640px] !lg:w-2/3' : 'lg:w-1/2'}
              ${item.imageSize == '1/5' && 'hidden lg:block lg:w-1/5'}
              ${item.imagePosition == 'left' ? 'lg:order-1' : 'lg:order-2'}
              ${router.pathname == '/affiliate' && 'hidden md:block'}
              ${
                router.pathname == '/wader-warranty' &&
                '!block max-h-[245px] md:max-h-[100%] overflow-hidden md:overflow-auto'
              }
            `}
          >
            <div
              className={`
                content-horizontal__image relative h-full w-full aspect-square bg-center bg-cover
                ${router.pathname == '/warranties' && 'max-h-[245px] md:max-h-full'}
              `}
            >
              <div className="imageBackground h-full content2_block_video">
                {videoUrl && !videoError ? (
                  <DynamicVideoPlayer
                    url={videoUrl}
                    playing={true}
                    playsinline={true}
                    loop={true}
                    volume={0}
                    muted={true}
                    width="100%"
                    height="100%"
                    onError={() => setVideoError(true)}
                  />
                ) : (
                  loadImage()
                )}
              </div>
            </div>
          </div>

          <div
            className={`
              flex flex-col lg:justify-center h-full md:mb-5 lg:mb-0
              ${
                item.imageSize == '1/5'
                  ? 'lg:w-4/5 py-[32px] md:py-[68.5px] px-[20px]'
                  : 'px-6 py-8 md:py-15 w-full lg:w-1/2'
              }
              ${item.imagePosition == 'left' ? 'lg:order-2' : 'lg:order-1 '}
              ${item.imageSize == '2/3' ? 'md:px-[58px] lg:max-w-[33.3%]' : 'md:px-[80px]'}
            `}
          >
            {item.eyebrow ? (
              <span
                className={`module-content-2blocks-title title text-xs md:text-xs uppercase font-semibold md:font-bold leading-[16px] md:leading-[15px] tracking-[0.1em] md:tracking-widest content2Block_content ${textClass} ${
                  textColor ? 'text-color' : ''
                }`}
              >
                {item.eyebrow}
              </span>
            ) : null}
            {item.headline ? (
              <div className={`${headlineStyle} font-bold tracking-[0.08em] uppercase content2Block_content ${textClass}`}>
                {item.headline}
              </div>
            ) : null}
            <div
              className={`list ${
                item.imageSize == '1/5' ? `content-custom-body prose max-w-none content2Block_content ${textClass}` : bodyTextStyle
              }`}
            >
              {item?.bodyText?.data
                ? documentToReactComponents(item.bodyText, renderOptions)
                : item.bodyText}
            </div>

            {primaryCta.url && primaryCta.label && (
              <div className={`${primaryCta.url ? 'flex' : ''} mt-10 md:mt-[32px]`}>
                {primaryCta.url && primaryCta.label ? (
                  <div
                    className={`${
                      !secondaryCta.url && !secondaryCta.label
                        ? 'md:w-[180px]'
                        : 'button-group-wrap flex'
                    } md:h-[56px] mr-2`}
                  >
                    <CTA
                      className={`btn btn-primary flex items-center justify-center tracking-widest py-[21px] px-[24px]`}
                      label={primaryCta.label}
                      openInNewTab={primaryCta.openInNewTab}
                      openVideoPlayer={primaryCta.openVideoPlayer}
                      url={primaryCta.url}
                      background={primaryCta.bgColor}
                      moduleProps={props}
                    />
                  </div>
                ) : null}

                {secondaryCta.url && secondaryCta.label ? (
                  <div className={`button-group-wrap flex`}>
                    <CTA
                      className={`btn btn-primary flex items-center tracking-widest py-[21px] px-[24px] md:mr-2 ${
                        item.imageSize == '2/3' && 'px-4'
                      }`}
                      label={secondaryCta.label}
                      openInNewTab={secondaryCta.openInNewTab}
                      openVideoPlayer={secondaryCta.openVideoPlayer}
                      url={secondaryCta.url}
                      background={secondaryCta.bgColor}
                      moduleProps={props}
                    />
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .text-color {
            color: ${textColor} !important;
          }
          .bg-color {
            background-color: ${bgColor};
          }
          :global(.content2Block_content.${textClass} *) {
            color: ${textColor};
          }
          @media (max-width: 767px) {
            :global(h3.content-custom-header) {
              font-size: 24px !important;
              letter-spacing: 0.08em;
              line-height: 32px;
            }

            :global(.content-custom-body p) {
              font-size: 14px !important;
              line-height: 22px;
            }

            :global(.content-custom-body li > p) {
              margin-top: 0 !important;
              margin-bottom: 1.25em !important;
            }

            :global(.list.content-custom-body *) {
              font-size: 14px !important;
              line-height: 22px;
            }
          }

          :global(li p) {
            word-break: break-word;
          }

          :global(li a) {
            word-break: break-word;
          }

          :global(.list li::marker) {
            color: transparent;
          }

          :global(.list li > ol) {
            padding-left: 0;
          }

          :global(.list li > ol li > ul) {
            padding-left: 0;
          }

          :global(.list li > ul) {
            padding-left: 0;
          }

          .prose h4 {
            text-align: left;
            margin-bottom: 20px;
          }
          .prose li::marker {
            color: #000;
          }
          .prose li {
            margin: 0;
          }
          .prose a {
            text-transform: unset;
          }

          :global(.service-content-block p a) {
            display: inline-block;
            text-transform: inherit;
            letter-spacing: inherit;
          }
          .title {
              color: #4c4442;
          }
          .prose_depot_event * {
            font-size: 16px !important;
          }
          .imageBackground::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
          }
          :global(.content2_block_video video) {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            -o-object-position: center;
            object-position: center;
          }
        `}
      </style>
    </>
  )
}
